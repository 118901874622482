.Select-control {
  border-radius: 0;
  font-size: 1rem;
}

.Select-value-label {
  font-size: 0.875rem;
}

.Select-option {
  font-size: 1rem;

  &.is-focused, &.is-selected {
    background-color: $grey-color;
  }
}

.Select-arrow {
  border: none;
  position: relative;
  width: 35px;

  &:before {
    padding:22px 15px 10px;
    content: "\f3d0";
    color: white;
    background-color: $grey-color;
    font-family: Ionicons;
    font-size: 1.2rem;
  }
}

.Select.is-open > .Select-control .Select-arrow {
  &:before {
    content: "\f3d8";
  }
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
  border-color: #CCC;
}

.Select-clear-zone {
  padding: 0 0.75rem;
}

.Select--multi {
  .Select-control {
    height: 38px;
  }

  .Select-value {
    color: $white-color;
    background-color: $primary-color;
  }

  .Select-value-icon {
    padding: 2px 5px 0 5px;
  }
}
