.ag-theme-balham .ag-grid-cell-diff {
  line-height: 26px;
  border: 1px solid transparent;
  padding-left: 0;
  padding-right: 0;

  &.ag-cell-focus {
    border: 1px solid #0091EA;
  }
}

.grid-cell-no-diff {
  padding: 0 0.5rem;
}

.grid-cell-diff {
  display: flex;

  .initial, .current {
    text-align: center;
    flex: 1;
    padding: 0 0.5rem;
  }

  .nochange {
    background-color: inherit !important;
  }

  .initial {
    background-color: #ffeef0;
  }

  .current {
    background-color: #e6ffed;
  }
}
