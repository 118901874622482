$primary-color:    #00AEEF;
$secondary-color:  #E1AE26;
$tertiary-color:   #A4DCF3;

$destructive-color: #DD514C;

// Greys
$white-color:      #FFFFFF;
$vericred-grey:    #4D4E50;
$grey-light-color: #F5F5F5;
$grey-color:       #E4E6E6;
$placeholder:      #D4D4D4;
$grey-norm-color:  #C2C2C2;
$grey-dark-color:  #373738;
$black-color:      $vericred-grey;
$near-black-color: #141414;

// Alerts
$alert-color:      #CA3C3C;
$error-color:      $alert-color;
$info-color:       $white-color;
$success-color:    #4F8A10;
$warning-color:    #9F6000;

$alert-bg-color:   #FFBABA;
$error-bg-color:   $alert-bg-color;
$info-bg-color:    $tertiary-color;
$success-bg-color: #DFF2BF;
$warning-bg-color: #FEEFB3;

// Form
$form-error-bg-color: #DD514C;
$form-error-color: $white-color;

$form-warning-bg-color: $secondary-color;
$form-warning-color: $near-black-color;
