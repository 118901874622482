@import 'Colors';

.benefits-set-table {
  width: 100%;
  table-layout: fixed;

  tbody tr:nth-child(odd) {
    background-color: $white-color;
  }

  tbody tr.header-row {
    background-color: $grey-light-color;
  }

  tbody tr.error-row {
    background-color: $form-error-bg-color;
    color: $form-error-color;
  }

  tbody tr {
    border: 1px solid $grey-norm-color;
    i.history_icon {
      svg {
        cursor: pointer;
      }
      display: table;
      width: 100%;
        .value-field-container {
          padding-right: 20px;
          display: table-cell;
          width: 100%;
        }
    }
  }

  col.label-column {
    width: 20rem;
  }

  .header-row .field {
    display: inline-block;
    margin-left: 1rem;
  }

  .header-row label {
    display: inline-block;
  }
}

.review-field-value {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.review-field-value .field {
  padding: 0 0.25rem;
}

.value-field-review-display {
  font-size: 0.85rem;
  border-radius: 4px;
  padding: 0.25rem;
  margin-top: 0.5rem;
  color: $form-warning-color;
  background-color: $form-warning-bg-color;

  i {
    margin-right: 0.25rem;
  }

  button {
    color: $form-warning-color;
  }

  .rejection-text {
    width: 100%;
  }

  .rejection-line {
    width: 100%;
  }

}

.rejection-line {
  margin-bottom: 0.25rem;
  font-size: 0.85rem;

  &:last-child {
    margin-bottom: 0;
  }

  time {
    font-size: 0.65rem;
    font-weight: 600;
  }
}

td.value-field {
  height: 1px;
  white-space: pre-wrap;
}

.value-field-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

td.required {
  color: $primary-color;
}
