@import 'Colors.scss';
@import 'Styleguide.scss';
@import 'PureForm.scss';
@import 'PureMenu.scss';
@import 'ReactDropdowns.scss';
@import 'FlashMessages.scss';

.MainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 1rem auto;
}

.login-container
.password-reset-container {
  width: 50%;
  min-width: 15rem;
  max-width: 25rem;
  margin-top: 3rem;
}

.google-login {
  padding: 1.5rem 0;
}

.google-login-button {
  width: 100%;
  color: white;
  background: rgb(202, 60, 60);
}

.error-header {
  background-color: #dd514c;
  color: white;
  padding: 0.5rem 0.25rem;
  margin: 0.5rem 0;
  border-radius: 4px;
}


.text-button {
  -webkit-appearance: none;
  border: none;
  background: none;
  padding: 0;
  color: #1D57AF;
  text-decoration: none;
}

.link-button {
  background-color: transparent;
  border: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  padding: 0;

  &:hover, &:focus {
    color: $secondary-color;
    outline: none;
    transition: all 0.15s;
  }
}

.radio-set-value {
  display: inline-block;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.radio-set-value:first-child {
  margin-left: 0;
}

.radio-set-value:last-child {
  margin-right: 0;
}

.borderless-table {
  border: none;
}

.borderless-table thead {
  background-color: white;
}

.borderless-table td {
  border: none;
}

.full-width-table-container {
  width: 100%;
  border: 1px solid #cdcdcd;
  overflow-x: auto;

  table {
    border: none;
    width: 100%;
  }

  th, td {
    white-space: nowrap;
  }

  td.cell-notes {
    max-width: 300px;
    overflow-x: auto;
  }
}

.actions-cell, .actions-header-cell {
  text-align: center;
}

table tbody tr.header-row {
  background-color: #E0E0E0;
}

.header-cell {
  vertical-align: top;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  a, a:hover {
    color: $black-color;
  }

  .header-label {
    flex: 1;
    margin-right: 1rem;
    white-space: nowrap;
  }

  .header-actions {
    white-space: nowrap;
  }

  &.current .header-order {
    display: inline;
  }

  a:hover .header-order {
    display: inline;
  }

  .hidden-button {
    -webkit-appearance: none;
    border: none;
    color: $white-color;
    padding: 0.25rem 0.35rem;
    font-size: 0.75rem;
    border-radius: 5px;
    margin-left: 0.75rem;
    font-weight: normal;
    display: inline-flex;
    align-items: center;
    visibility: hidden;
    white-space: nowrap;

    &:hover {
      color: $white-color;
    }

    i {
      font-style: normal;
      margin-left: 0.5rem;
      color: $white-color;
    }

    .normal-visible {
      visibility: visible;
    }

    .normal-hidden {
      visibility: hidden;
    }
  }

  .hidden-button.current i {
    visibility: visible;
    color: $black-color;
  }

  .hidden-button.current i {
    visibility: visible;
    color: $black-color;
  }

  .filter-button {
    background-color: $secondary-color;
    border: 1px solid darken($secondary-color, 5%);
    color: $white-color;
  }

  .remove-filter-button {
    background-color: $secondary-color;
    border: 1px solid darken($secondary-color, 5%);
    color: $white-color;
  }

  .sort-button {
    background-color: $primary-color;
    border: 1px solid darken($primary-color, 5%);
    color: $white-color;
  }

  &:hover {
    .hidden-button {
      visibility: visible;

      .normal-hidden {
        visibility: visible;
      }
    }

    .hidden-button.current i {
      color: $white-color;
    }
  }
}

.external-id-field {
  border: 1px solid gray;
  padding: 0.25rem;
  margin: 0.5rem 0;
  border-radius: 5px;
}

.census-selection, .zip-counties-selection, .audience-selection, .quarter-selection {
  width: 50%;
  margin: 2rem 0;
}

.family {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0.5rem;
  position: relative;
}

.family .remove-button {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.remove-button {
  color: #D0021B;
}

.family .remove-button:hover,
.family .remove-button:active,
.family .remove-button:focus {
  color: #D0021B;
}

.family:first-child {
  margin-top: 0;
}

.family:last-child {
  margin-bottom: 0;
}

.family-members {
  margin: 1rem 0;
}

.family-member-table tr {
  text-align: center;
}

.family-member-table .label {
  width: 10rem;
}

.family-member-table .age {
  width: 6rem;
}

.family-member-table .tobacco {
  width: 5rem;
}

.family-member-table .gender {
  width: 5rem;
  text-align: left;
}

.family-member-label {
  text-align: right;
}

.howie_info {
  color: $white-color;
  background-color: $info-bg-color;
}
.howie_success {
  color: $success-color;
  background-color: $success-bg-color;
}
.howie_warning {
  color: $warning-color;
  background-color: $warning-bg-color;
}
.howie_error {
  color: $error-color;
  background-color: $error-bg-color;
}
.howie_info, .howie_success, .howie_warning, .howie_error {
  text-align: center;
  margin: 5px 0;
  padding: 15px;

  span {
    margin:10px 22px;
    font-size:1em;
    vertical-align:middle;
  }
}
.plans-approval-components button:last-child{
  margin-left: 3px;
}

.approval-state {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    margin-right: 0.5rem;
  }
}

.success {
  color: $success-color;
}

.error {
  color: $error-color;
}

.warning {
  color: $warning-color;
}

.value-field-value {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.value-field-value .field {
  padding: 0 0.25rem;
}

.info-group-review {
  color: $error-color;
  margin-top: 0.25rem;
}

.info-group-review-text {
  vertical-align: middle;
}

.source-contents-input {
  display: table-cell;
  max-width: 700px;

  .text-field, .checkbox-field {
    max-width: 33%;
    vertical-align: middle;
  }

  .checkbox-field {
    display: flex;

    .field {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  label {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;

    .field {
      float: right;
    }

    .label {
      float: left;
      margin-right: 0.5rem;
      text-align: right;
    }
  }
    .source-content-type-label {
      justify-content: left;
      width: 33%;
    }
}

.document-issuer,
.document-source-content {
  max-width: 350px;
  overflow: auto;

  table {
    table-layout: fixed;

    tbody {
      tr {
        background-color: inherit;
      }
      td {
        text-align: left;
      }

      .source-content {
        max-width: 90px;
        min-width: 90px;
        width: 90px;
      }

      .pages {
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }

      .affected-records {
        max-width: 150px;
        min-width: 150px;
        width: 150px;
      }
    }
  }
}

.document-issuer {
  max-width: 460px;

  .issuer-id {
    max-width: 50px;
    min-width: 50px;
    width: 50px;
  }

  .issuer-name {
    max-width: 410px;
    min-width: 410px;
    width: 410px;
  }
}

.source-document-table {
  max-width: 300px;
  min-width: 300px;
  width: 300px;

  table {
    table-layout: fixed;

    tbody {
      tr {
        background-color: inherit;
      }
      td {
        text-align: left;
      }

      .source-document-url {
        max-width: 150px;
        min-width: 150px;
        width: 150px;
      }

      .source-document-id {
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }
    }
  }
}

.react-dropzone-s3-uploader {
  margin: 0.25rem 0;
  width: 100%;
  border: dashed 2px #999;
  border-radius: 5px;
  box-sizing: border-box;

  .uploader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
  }

  .drag-and-drop-water-mark {
    width: 100%;
    vertical-align: middle;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-image {
    padding-left: 10px;

    img {
      height: 50px;
      width: auto;
      border: solid thin $white-color;
    }

    a:hover img {
      border: solid thin $primary-color;
    }
  }

  .error {
    color: #CA3C3C !important;
    padding: 0 !important;
    background-color: #ffffff;
    border-radius: 5px;
  }

  .file-name {
    padding-left: 10px !important;
  }

  .file-progress {
    flex: 1;
    padding: 0 2rem;
    text-align: center;
  }

  .file-icon {
    padding-right: 10px !important;
    text-align: right;
    vertical-align: middle;
    line-height: 30px;
  }

  progress {
    width: 100%;
    height: 30px;
  }
}

.form-header, .main-header {
  margin-bottom: 0.5rem;

  nav .button {
    margin: 0;
    padding: 0.75rem 1rem;
  }

  & > div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .form-header-actions, .header-actions {
    padding: 1rem 0;
  }

  .search input {
    min-width: 500px;

    &::-webkit-search-cancel-button {
      position: relative;
      right: 20px;
    }
  }
}

@keyframes highlightBackgroundChange {
  0%   { background: lighten($primary-color, 15%) }
  100% { background: inherit; }
}

.highlight {
  background-color: inherit;
  animation: highlightBackgroundChange 5s;
}

.deleted-record {
  background-color: $grey-norm-color !important;
}

.modal {
  position: absolute;
}

.ipa-rejection-modal,
.ipa-action-modal,
.ipa-publish-errors-modal,
.restore-plan-product-modal,
.filter-modal,
.bulk-publish-modal,
.history-modal ,
.publish-error-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 100%;

  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;

  .modal-inner {
    position: relative;
    background-color: $grey-light-color;
    width: 50%;
    min-width: 300px;
    padding: 3rem;
    max-height: 80%;
    overflow: scroll;
  }

  .select-button {
    margin-top: 2rem;
    width: 100%;
  }

  .cancel-button {
    @extend .text-button;

    position: absolute;
    top: 0.5rem;
    right: 0.75rem;
    font-size: 1.5rem;

    color: $black-color;

    &:hover {
      color: $black-color;
    }
  }
}

.history-modal {
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  .history_record {
    padding: 10px;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 20px;
    border-bottom: 2px solid black;
    div {
      width: 50%;
      margin-bottom: 5px;
    }
    .history_record_value{
      width: 100%;
      margin-bottom: 15px;
      span, b {
        background-color: $grey-norm-color;
        padding: 2px 5px 2px 5px;
      }
      line-height: normal;
      white-space: pre-wrap;
    }
  }

  .current {
    border: 2px solid $primary-color;
  }
}
.ipa-action-modal,
.ipa-rejection-modal {
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  .modal-inner {
    text-align: center;
  }

  .rejection-message {
    width: 100%;
  }
}

.modal-button {
  background-color: transparent;
  border: none;
  vertical-align: middle;

  svg {
    margin-left: .25rem;
    vertical-align: top;
  }

  &:hover {
    text-decoration: underline;
  }
}

form {
  .inline-fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    & > div {
      flex: 1;
      margin: 0 1rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

p {
  &.valid {
    color: $success-color;
  }

  &.invalid {
    color: $form-error-bg-color;
  }
}

.value-field-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.value-field-error-container {
  font-size: 0.85rem;
  border-radius: 1rem;
  padding: 0.15rem;
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: $form-error-color;
  background-color: $form-error-bg-color;
  list-style-position: inside;

  .error-line {
    padding: 0.15rem 0.5rem;
  }
}

.message-container {
  padding: 0.5rem 1rem;
  background-color: $success-color;
  color: $white-color;
  border-radius: 1rem;
  border: 0.05rem solid lighten($success-color, 10%);

  p {
    margin: 0.25rem 0;
  }
}

.data-grid {
  width: 100%;
  height: 75vh;
}

.note {
  font-style: italic;
  font-size: 0.85rem;
}
