.flex-box {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  &-item {
    flex: 1;
    margin-right: 1em;

    &-fixed {
      flex: 0 0 auto;
      margin-right: 4em;
    }
  }
}
