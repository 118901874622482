@import 'Colors.scss';

.flash-message-container {
  margin: 1rem 0;
  padding: 0;

  p {
    color: $near-black-color;
    margin: 0.25rem 0;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background-color: $tertiary-color;
    border: 0.125rem solid darken($tertiary-color, 10%);

    &.alert {
      color: $alert-color;
      background-color: $alert-bg-color;
      border: 0.125rem solid darken($alert-bg-color, 10%);
    }

    &.success {
      color: $success-color;
      background-color: $success-bg-color;
      border: 0.125rem solid darken($success-bg-color, 10%);
    }
  }
}

.general-flash-message {
  .flash-message-container {
    margin: 1rem;
  }
}
