.pure-menu-item {
  a {
    font-weight: normal;
  }

  a, a:visited {
    color: #777;
  }

  button.pure-menu-link {
    background-color: transparent;
    border: none;
  }
}

.pure-menu-parent {
  left: 100% !important;
  top: 0 !important;
}

.menu-link-right-arrow {
  &:after {
    content: '' !important;
  }
}
