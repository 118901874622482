.tabs {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}
.tabs__labels {
  margin: 0;
  padding: 0;
  width: 100%;
}
.tabs__labels li {
  display: inline-block;
  background: #eee;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  margin: 0;
}

.tabs__labels li:last-child {
  border-right: 1px solid #e5e5e5;
}

.tabs__labels li:hover {
    background-color: #ddd;
}

.tabs__labels li a {
  padding: 8px 12px;
  display: block;
  color: #444;
  text-decoration: none;
  cursor: pointer;
}

.tabs__labels li.active {
  background: #fff;
  border-bottom: 0px;
}

.tabs__labels li.active a {
 font-weight: bold;
 border-top: 1px solid rgb(0, 120, 231);
}

.tabs__content {
  padding: 10px;
}
