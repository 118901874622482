//IMPORT OPEN SANS FONT FROM GOOGLE
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700');
//IMPORT FONT AWESOME CSS VIA CDN
@import url('//code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');

@mixin primary-fontfamily() {
  font-family: 'Open Sans', "Helvetica Neue", sans-serif;
}

@mixin box-shadow() {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 7px 0 rgba(0, 0, 0, 0.15);
}

@mixin inner-box-shadow() {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0px 7px 0 rgba(0, 0, 0, 0.15);
}

@mixin border-radius() {
  border-radius: 0;
}

//SET GLOBAL DEFAULTS
* {
  letter-spacing: normal;
  border-radius: 0;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  color: $vericred-grey;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body *:not(.fa) {
  @include primary-fontfamily();
}

//TYPE SETTINGS
h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
  line-height: 1.75em;
  margin: 0;

}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.7rem;
}

h4 {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1.2rem;
  letter-spacing: 2px;
}

.hbr {
  border-bottom: solid thin $grey-norm-color;
  width: 100%;
  padding-bottom: 20px;
  margin: 60px 0 30px;
}

img.guide-logo {
    max-width: 130px;
    margin-right: 30px;
}

.element-spacing{
  padding: 40px 0;
}

p {
  font-size: 16px;
  font-weight: 300;
}

.hero-title {
  font-size: 50px;
}

.underline {
  border-bottom: 1px solid $grey-color;
  margin-bottom: 1em;
  padding-bottom: .5em;
}

.title {
  font-size: 30px;
}

.hero-tagline {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 200;
}

strong {
  font-weight: 600;
}

blockquote {
  border-left: solid 4px $primary-color;
  background-color: whitesmoke;
  padding: 1rem;
}

//Content links
a, a:visited {
  text-decoration: none;
  color: $primary-color;
  font-weight: 700;

  &:hover {
    color: $secondary-color;
    transition: all 0.15s;
  }

  > svg {
    vertical-align: middle;
  }
}

small, label {
  font-size: .8rem;
  font-weight: 400;
  color: $placeholder;
}

hr {
  border: solid thin $tertiary-color;
  display: inline-block;
  margin: 1.5rem 0;
  width: 100%;
}

//REUSABLE CLASSES

.left {
  float: left;
}

.right {
  float: right;
}

.caps {
  text-transform: uppercase;
}

.thin {
  font-weight: 300;
}

.primary {
  color: $primary-color;
}

.secondary {
  color: $secondary-color;
}

.dark {
  color: $black-color;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase
}

.buffer {
  &-top {
    margin-top: 2rem;
  }

  &-bottom {
    margin-bottom: 2rem;
  }
}

.align-items-center {
  align-items: center;
}

.clear {
  clear: both;
  display: inline-block;
  width: 100%;
  float: none;
}

//SETS THE MULTI-COLORED BORDER AROUND THE CONTENT AND FRAMES IT IN
.box-branded {
  position: relative;
  padding: 1rem 1rem 2rem;
}

//Logos
.logos img {
  vertical-align: middle;
  margin: 1rem 3rem 0 0;
@media only screen and (max-width:767px){
  margin:1rem auto 2rem;
  display:block;
}
}

//LAYOUT STYLES
.content {
  margin: 0 auto;
  padding: 0 1rem;
}

//FORM STYLES

// input {
//   margin: 0 1rem 1.5rem 0;
// }

//Label Defaults
label {
  display: block;
  margin: .75rem 0;
  font-size: 1.1875rem;
  color: $black-color;
  font-weight: 300;
}

//Validation
span.error {
  color: $alert-color;
  text-transform: initial;
  font-size: 1rem;
  margin-left: .25rem;
  text-align: right;

}

//Set defaults on basic text type inputs
input[type=password],
input[type=number],
input[type=search],
input[type=text],
input[type=email],
input[type=address],
input[type=date],
input[type=tel] {
  @include border-radius();
  height: 2.9rem;
  padding: 0 .75rem;
  border: solid thin $grey-color;
  font-size: 1rem;

  &:focus {
    outline: solid thin rgba(0, 0, 0, .2);
    @include inner-box-shadow();
    border: solid thin $black-color;
  }

  &.validate {
    border-color: $alert-color;
    @include inner-box-shadow();
  }
}

input::placeholder {
  color: $placeholder;
}

//Inline checkbox and radio labels
input[type=checkbox] + label,
input[type=radio] + label {
  margin-left: 0.5rem;
  display: inline;

}
input[type=checkbox]:checked.mute{
  pointer-events:none;
  &::before{
        color: $grey-norm-color;
  }
}
input[type=radio], input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 999px;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  box-shadow: 0 0 1px black;
  border: solid white 4px;
  transition: all 0.25s ease-in;
  margin: 0;

  &:active {
    @include box-shadow();
  }

  &.medium {
    width: 1.2em;
    height: 1.2em;
  }

  &.large {
    width: 1.6rem;
    height: 1.6rem;
  }
}

//Radios
input[type=radio]:checked {
  outline: none;
  background-color: $primary-color;
}

form.compact {
  input {
    height: 2.5rem;
    margin: 0;
    box-shadow: none;
    float: left;
    padding: 0 .75rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 0;
  }
  input[type=submit].button {
    height: 2.6rem;
    font-size: .8rem;
  }
}

input.connected-search-input {
  height: 2.5rem;
  margin: 0;
  box-shadow: none;
  padding: 0 .75rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

button.connected-search-button {
  box-shadow: none;
  padding: 0 .75rem;
  height: 2.6rem;
  font-size: .8rem;
  margin: 0;
}

//Color Palette
.palette {
  margin: 0 -.5rem;
  > div p {
    margin: .5rem 0;
    font-size: .8rem;
    padding-left: .5rem;
  }
  .panel {
    background-color: $primary-color;
    border: solid 8px white;
    display: block;
    padding: 5rem 1rem 1rem;
    line-height: 1.4rem;
    color: white;

    &.secondary {
      background-color: $secondary-color;
    }

    &.tertiary {
      background-color: $tertiary-color;
    }

    &.gray {
      background-color: $black-color;
    }

    &.light-gray {
      background-color: $grey-color;
      color: $black-color;
    }

  }

}

//Checkboxes
input[type=checkbox] {

  @include box-shadow();
  border: solid thin rgba(0, 0, 0, .1);
  background-color: $grey-color;
  position: relative;
  border-radius: 0;
  &:before {
    content: "\f122";
    font-family: Ionicons;
    position: absolute;
    bottom: -4px;
    left: 2px;
    font-size: 1.2rem;
    color: transparent;
  }

  &.medium:before {
    font-size: 1.4rem
  }

  &.large:before {
    font-size: 1.8rem;
  }

  &:checked:before {
    color: $primary-color;

  }
  &:focus {
    outline: none;
  }
  &:active {
    &:before {
      color: rgba(0, 0, 0, .2);

    }
  }
}

//Submit button defaults
input[type="submit"] {
  @include box-shadow();
  -webkit-appearance: none;
  -moz-appearance: none;

  border: none;
  padding: 0 2rem;
  height: 2.9rem;
  vertical-align: top;

  &:focus {
    outline: none;
  }
}

input[type="submit"], button {
  &.primary {
    border: none;
    vertical-align: top;

    background-color: $primary-color;

    &:hover {
      background-color: $black-color;
    }
  }
}

//Accordions
.accordion {
  margin: 1rem 0;
  .accordion-header {
    display: block;
    padding: .25rem 1.5rem .25rem 4.5rem;
    background-color: $grey-light-color;
    position: relative;
    &:before {
      content: "\f3d3";
      font-family: Ionicons;

      position: absolute;
      top: 19px;
      font-size: 20px;
      font-weight: bold;
      left: 2rem;
    }

    &:hover {
      cursor: pointer;
      *, &:before {
        color: $primary-color;
      }
    }


  }
  .accordion-content {
    height: 0;
    overflow: hidden;
    margin:0;
    list-style-type: none;
    display:block;
    padding:0;

    li{
      padding:15px 75px;
      border-bottom: 1px solid $grey-norm-color;
      &:last-child{
        border-bottom: none;

      }
    }
  }


  &.open .accordion-header {
    *, &:before {
      color: $primary-color;
      content: "\f3d0";

    }
  }
  &.open .accordion-content {
    height: auto;
    overflow: initial;
  }
}

//Tabs
ul.tabs {
  margin: 2rem 0 0;
  padding: 0 0 0 3rem;
  list-style: none;
  border-bottom:solid thin $grey-color;
  li.tab {
    @media only screen and (min-width: 768px) {
      display: inline;
      text-transform: uppercase;
      float: left;
    }
    a {
      border: solid thin $grey-color;

      font-weight: 300;
      padding: .5rem 1.5rem;
      display: block;
      @media only screen and (min-width: 768px) {
        border-width: 1px 0 0 1px;
      }
    }

    &:last-child a {
      border-width:1px 1px 0 1px;
    }
  }

  li.tab:hover a, li.tab.active a {
    background-color: $primary-color;
    color: white;
    border-color: $primary-color;
  }
}

//Tables
table {
  thead {
    text-align: left;
  }
  td, th {
    padding: 15px 5px;
  }

  tbody tr:nth-child(odd) {
    background-color: $grey-light-color;
  }

}

.pagination-info {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

div.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 2rem 0;

  a, span.disabled-link, span.active, span.separator {
    font-weight: 300;
    text-align: center;
    padding: .2rem .5rem;
    vertical-align: middle;

    @media only screen and (min-width:768px) {
      padding: .2rem 1rem;
    }
  }

  a {
    color: $black-color;
  }

  span.disabled-link {
    color: $grey-color;
  }

  a:hover, span.active {
    background-color: $primary-color;
    color: white;
  }

  &.first, &.prev, &.next, &.last {
    a, span.disabled-link {
      padding: .2rem 1rem;
      font-weight: 400;
    }
  }
}

//Pagination
ul.pager {
  list-style: none;
  display: inline-block;
  width: 100%;
  padding: 0;
  text-align: center;
  margin: 1rem 0;

  li {
    display: inline;

    a {
      font-weight: 300;
      color: $black-color;
      text-align: center;
      padding: .2rem .5rem;

      @media only screen and (min-width:768px) {
      padding: .2rem 1rem;
      }

      &:hover, &.active {
        background-color: $primary-color;
        color: white;
      }
    }

    &.first, &.prev, &.next, &.last {
      float: right;
      a {
        padding: .2rem 1rem;
        font-weight: 400;
      }
    }

    &.first, &.prev {
      float: left;
    }
  }
}

// Header
header.main {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  h1 {
    color: $primary-color;
  }

  nav {
    flex: 1;
    padding: 0 2rem;

    .pure-menu-list {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      list-style-type: none;
    }

    &.pure-menu-horizontal .pure-menu-item {
      vertical-align: baseline;
    }

    .pure-menu-children {
      box-sizing: border-box;
      border: 1px solid $grey-color;
      min-width: 100%;

      .pure-menu-link {
        font-weight: 200;
      }
    }

    .pure-menu-link {
      cursor: pointer;

      &.pure-menu-disabled {
        cursor: default;
      }
    }
  }

  .actions {
    justify-self: right;
  }
}

// Buttons
.quiet-button {
  color: $black-color;
  background: none;
  box-shadow: none;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.5rem 1rem;
  border-radius: 0;
  transition: all 0.15s;
  border: none;

  &:hover {
    @include box-shadow();
    background-color: $secondary-color;
    color: white;
  }
}

// Flash Messages
.general-flash-message {
  .flash-message-container {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

// Action nav
.action-nav {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 1.5rem 0;

  &-item {
    flex: 0 0 1;
    &--right {
      margin-left: auto;
    }
  }
}

// Date Ranges
.date-range-container {
  .field {
    display: flex;

    input[type="date"] {
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5rem;
    }

    &.field-with-error {
      padding-bottom: 0;
    }
  }

  .error {
    margin: 0 0.5rem;
  }
}

div.ag-root-wrapper {
  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  label {
    margin: 0;
  }

  input[type=password],
  input[type=number],
  input[type=search],
  input[type=text],
  input[type=email],
  input[type=address],
  input[type=date],
  input[type=tel] {
    box-shadow: inherit;
    height: inherit;
    padding: inherit;
    border: inherit;
    font-size: inherit;

    &.ag-cell-edit-input {
      padding: 10px;
    }

    &.ag-column-name-filter {
      padding: 4px 5px;
    }
  }

  &:focus {
    outline: inherit;
    box-shadow: inherit;
    border: inherit;
  }
}
