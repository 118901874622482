@import 'Colors.scss';

.section {
  position: relative;
  width: 100%;
  border: 1px solid $grey-color;
  margin-bottom: 2px;

  button {
    position: absolute;
    right: 0;
    margin: 0;
    padding: 0;
    height: 3em;
    width: 3em;
    outline: 0;
    border: 0;
    background: none;
    text-indent: -9999%;
    pointer-events: none;
  }

  button:before {
    content: '';
    display: block;
    position: absolute;
    height: 12px;
    width: 4px;
    border-radius: .3em;
    background: grey;
    transform-origin: 50%;
    top: 50%;
    left: 50%;
    transition: all .25s ease-in-out;
    transform: translate(75%, -50%) rotate(45deg);
  }
  button:after {
    content: '';
    display: block;
    position: absolute;
    height: 12px;
    width: 4px;
    border-radius: .3em;
    background: grey;
    transform-origin: 50%;
    top: 50%;
    left: 50%;
    transition: all .25s ease-in-out;
    transform: translate(-75%, -50%) rotate(-45deg);
  }

  &.open {
    button:before, button:after {
      height: 14px;
    }
    button:before {
      transform: translate(0%, -50%) rotate(-45deg);
    }
    button:after {
      transform: translate(0%, -50%) rotate(45deg);
    }
    .articlewrap {
      height: auto;
    }
  }
}

.sectionhead {
  background: white;
  cursor: pointer;
  font-weight: 700;
  white-space: nowrap;
  padding: 1em;
  padding-right: 2.1em;
}

.section-header-title{
  padding: .5rem;
  padding-right: 1.5em;
  white-space: nowrap;
  overflow: hidden;
}

.articlewrap {
  height: 0;
  overflow: hidden;
  transition: all .2s ease-in;
}

.article {
  padding: 1em;
  line-height: 1.3;

  table{
    width: 100%;
  }
}

.accordion-title{
  white-space: normal;
  padding-right: 2.3em;
}
