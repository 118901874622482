.pure-form {
  .field {
    padding: 0.25rem 0;
  }

  .Select .Select-control {
    margin: 0.25rem 0;
  }

  .select-tooltip {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1.5rem 0;

    &-tip {
      flex-grow: 1
    }
    &-select {
      flex-grow: 10
    }
    .tooltip-content {
      right: 100%;
      left: unset;
      z-index: 9999;
    }
  }

  .field-with-error {
    input, select, .Select .Select-control {
      border-color: $form-error-bg-color;
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;
    }
  }

  .error {
    font-size: 0.85rem;
    padding: 0.25rem 0.25rem;
    color: $form-error-color;
    background-color: $form-error-bg-color;
    border-radius: 0 0 4px 4px;
  }

  .Select-input > input {
    padding: 8px 0 12px 0;
    border: inherit;
    box-shadow: inherit;
    margin: inherit;
    display: inherit;
  }

  button.pure-menu-link {
    -webkit-appearance: none;
    border: none;
    background: none;
  }

  .pure-button-destructive {
    color: white;
    background: $alert-color;
  }

  .pure-radio span {
    margin-left: 0.5rem;
  }

  input[type=password],
  input[type=number],
  input[type=search],
  input[type=text],
  input[type=email],
  input[type=address],
  input[type=tel],
  input[type=date] {
    @include border-radius();
    font-size: 1rem;
    box-shadow: none;

    &:focus {
      outline: solid thin rgba(0, 0, 0, .2);
      @include inner-box-shadow();
      border: solid thin $black-color;
    }

    &.validate {
      border-color: $alert-color;
      @include inner-box-shadow();
    }
  }
}

.button, .pure-button, a.button {
  @include box-shadow();
  background-color: $secondary-color;
  color: white;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.7rem 2rem;
  border-radius: 0;
  transition: all 0.15s;
  margin: 0;

  &.small {
    padding: 0.35rem 1rem;
    font-size: 1rem;
  }

  &:hover, &.quiet-button:hover {
    @include box-shadow();
    background-color: $black-color;
    color: white;
  }

  &.destructive {
    border: 0;
    color: $white-color;
    background-color: $destructive-color;
  }

  &.approve {
    color: $white-color;
    background-color: $success-color;
  }

  &.reject {
    color: $white-color;
    background-color: $error-color;
  }

  &.dark {
    background-color: $black-color;
    &:hover {
      background-color: $grey-dark-color;
    }
  }

  &.pure-button-primary {
    background-color: $primary-color;
    margin-right: 5px;
  }

  i:before {
    font-size: 16px;
    font-weight: bold;
    transform: translate(6px, 0px);
    transition: transform .15s ease-in;
  }

  &.move-right:hover i:before {
    transform: translate(12px, 0px);
  }

  &.move-down:hover i:before {
    transform: translate(6px, 2px);
  }

  &.quiet-button {
    color: $primary-color;
    background: none;
    box-shadow: none;
    display: inline-block;
  }
}

.search-input-wrapper {
  float: left;
  position: relative;

  .clear-button-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;

    width: 3rem;

    align-items: center;
    justify-content: center;
    color: $grey-norm-color;

    button {
      padding: 0.25rem;
      height: auto;
      background: none;
      border: none;
      font-size: 1rem;
      display: flex;

      &:hover {
        color: $grey-dark-color;
      }
    }
  }

  input:focus + .clear-button-container {
    display: flex;
  }

  &:hover {
    .clear-button-container {
      display: flex;
    }
  }
}

.Select.is-disabled > .Select-control, .Select.has-value.is-disabled > .Select-control {
  background-color: #EAEDED;

  .Select-value .Select-value-label {
    color: #CAD2D3;
  }
}
