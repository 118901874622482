@import 'Colors.scss';

.tooltip {
  $base-font-size: 13px;

  display: inline;
  margin: 0 1rem;
  position: relative;
  vertical-align: middle;

  &-title {
    cursor: pointer;
    font-size: $base-font-size;
    line-height: 2.8;

    &:hover {
      text-decoration: underline;
    }
  }

  &-content {
    background-color: $white-color;
    border-radius: 2px;
    box-shadow: 0 0 3px rgba(0,0,0,0.6);
    left: 100%;
    margin-left: 20px;
    width: 200px;
    padding: 15px;
    position: absolute;
    top: -10px;

    ol {
      font-size: $base-font-size;
      margin: 10px 0;
      padding: 0 0 0 20px;
    }

    li {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    pre {
      background: $grey-light-color;
      display: inline-block;
      color: $vericred-grey;
      font-size: 90%;
      font-weight: bold;
      margin: 10px 0 0 0;
      padding: 5px;
    }

    p {
      font-size: $base-font-size;
      margin: 0;

      &:first-of-type {
        margin: 10px 0 5px 0;
      }
    }

    strong {
      display: block;
      margin: 5px 10px 0 0;
    }

    
  }
}
